import { Button, Checkbox, Form, Input, Space } from "antd";

import blogServices from "@/services/blog";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import Link from "next/link";

export default function BannerContent(props) {
  const { t } = useTranslation("");
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [submited, setSubmited] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const data = {
        email: values.email || "",
        source_link: window.location.href,
      };
      await blogServices.newSubscribe(data);
      setLoading(false);
      setSubmited(true);
      setEmail("");
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="content relative z-[1]">
        <h2
          dangerouslySetInnerHTML={{
            __html: (props.banner && props.banner.title) || "",
          }}
          className="mb-8"
        ></h2>
        <p
          dangerouslySetInnerHTML={{
            __html: (props.banner && props.banner.desc) || "",
          }}
          className="text-body1"
        ></p>
        <Form form={form} onFinish={handleSubmit} name="request-form">
          <div className="flex items-start sm:flex-col gap-x-2 max-w-[540px] mt-14">
            <Form.Item
              rules={[
                { required: true },
                {
                  type: "email",
                  message: t("validate.email", { ns: "common" }),
                },
              ]}
              className="mb-0"
              name="email"
            >
              <Input
                size="small"
                placeholder="Email"
                className={`${
                  props.classInput ? props.classInput : "text-white"
                } w-full min-w-[320px] border-[#5e5e5e]`}
              />
            </Form.Item>
            <Form.Item className="mb-0">
              <Button
                className="px-6 text-white sm:w-full sm:mt-4"
                htmlType="submit"
                type="primary"
                loading={loading}
                disabled={submited}
              >
                {submited
                  ? t("button.subscribed", { ns: "common" })
                  : t("button.subscribe", { ns: "common" })}
              </Button>
            </Form.Item>
          </div>
          <Form.Item
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(t("validate.agree", { ns: "common" }))
                      ),
              },
            ]}
            name="agreement"
            valuePropName="checked"
            className="mt-3"
          >
            <Checkbox className={`${props.classCheckbox} text-[#A5ABB3]`}>
              <p className="text-body3">
                {t("form.agree", { ns: "common" })}{" "}
                <Link href="/terms-of-service">
                  <span className="text-[#3564D0]">
                    {t("shared.terms_of_service", { ns: "common" })}
                  </span>
                </Link>
                <span className="mx-1">
                  {t("shared.and", { ns: "common" })}
                </span>
                <Link href="/privacy">
                  <span className="text-[#3564D0]">
                    {t("shared.privacy", { ns: "common" })}
                  </span>
                </Link>
                <span>{t("form.of_cystack", { ns: "common" })}</span>
              </p>
            </Checkbox>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
