import { Card } from "antd";
import Link from "next/link";
import dayjs from 'dayjs'
import { useRouter } from 'next/router'

export default function CardPost(props) {
  var localizedFormat = require('dayjs/plugin/localizedFormat')
  dayjs.extend(localizedFormat)
  const formatDate = (value) => {
    return useRouter().locale === 'en' ? dayjs(value).format('LL').replace(',', '') : dayjs(value).format('DD/MM/YYYY');
  }

  const convertCategory = () => {
    const category = props?.postItem?._embedded['wp:term']?.[0]
    return category[0].name || ''
  }

  return (
    <Card bodyStyle={{ padding: 0, height: '100%' }} className="text-[#000519] border-none h-full">
      <div className="flex flex-col justify-between h-full">
        <figure className="relative flex-shrink-0 w-full overflow-hidden group aspect-3/2">
          {
            props.postItem._embedded['wp:featuredmedia'] && props.postItem._embedded['wp:featuredmedia'].length
              ?
              <img src={props.postItem._embedded['wp:featuredmedia']['0'].source_url}
                className="object-cover w-full h-full transition-all rounded-r cursor-pointer group-hover:scale-125"
                alt={props?.postItem?.title?.rendered || 'CyStack thumbnail highlight posts'}
              />
              :
              <div>
                {
                  props.type === 'learn' ?
                    <div className="w-full h-full transition-all group-hover:scale-125">
                      <div className="absolute -translate-y-1/2 content left-10 top-1/2">
                        <img src="/images/Logo-Learn.svg" className="max-h-[20px] object-contain mb-4" alt="CyStack logo learn" />
                        <h4 dangerouslySetInnerHTML={{ __html: props.postItem.title.rendered }} className="text-white line-clamp-2"></h4>
                      </div>
                      <img src="/images/DefaultThumbnailArticle.png" className="w-full h-full" alt="CyStack image thumbnail" />
                    </div>
                    :
                    <img src={props.defaultThumbnail} className="w-full h-full transition-all group-hover:scale-125" alt="CyStack image thumbnail" />
                }
              </div>
          }
        </figure>
        <div className="flex flex-col justify-between flex-1 h-full">
          <div>
            <Link href={`/${props.type}/${props.postItem.slug || ''}`}>
              <h6 className="mb-1 cursor-pointer hover:underline line-clamp-2" title={props.postItem.title.rendered || ''} dangerouslySetInnerHTML={{ __html: props.postItem.title.rendered }}></h6>
            </Link>
            <div className='meta flex items-center text-[10px] leading-5 mb-5 text-[#000519]'>
              <span>{formatDate(props.postItem.modified)}</span>
              <span className='px-2 text-dark'>|</span>
              <span className="uppercase" dangerouslySetInnerHTML={{ __html: convertCategory() || '' }}></span>
            </div>
          </div>
          {props.postItem.excerpt && props.postItem.excerpt.rendered && <div className='text-body3 line-clamp-2' dangerouslySetInnerHTML={{ __html: props.postItem.excerpt.rendered }}></div>}
        </div>
      </div>
    </Card>
  )
}