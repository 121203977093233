import ENDPOINT from "@/config/endpoint";
import request from "@/utils/request";
import { requestFull } from "@/utils/requestFull";

async function fetchCategory(params) {
  return await request({
    url: ENDPOINT.BLOG_CATEGORIES,
    method: "get",
    params
  });
}

async function fetchPosts(params) {
  // Validate params
  if (params.categories) {
    function isNumeric(str) {
      return /^\d+$/.test(str);
    }
    const fakeError = {
      response: {
        data: {
          message: "SENTRY_IGNORE_Invalid categories",
        },
        status: 404,
        statusText: "Not Found",
        headers: {},
        config: {},
      },
      message: "SENTRY_IGNORE_Invalid categories",
    }

    if (typeof params.categories === 'string') {
      if (!isNumeric(params.categories)) {
        return Promise.reject(fakeError)
      }
    }

    if (Array.isArray(params.categories)) {
      const isValid = params.categories.every(item => typeof item === 'string' && isNumeric(item))
      if (!isNumeric(isValid)) {
        return Promise.reject(fakeError)
      }
    }
  }
  return await requestFull({
    url: ENDPOINT.BLOG_POST,
    method: "get",
    params
  })
}

async function fetchBlogUser(params) {
  return await request({
    url: ENDPOINT.BLOG_USERS,
    method: "get",
    params
  });
}

async function subscribe(data) {
  return await request({
    url: ENDPOINT.BLOG_SUBSCRIBE,
    method: "post",
    data,
  });
}

async function fetchBlogTags(params) {
  return await request({
    url: `${ENDPOINT.BLOG_TAGS}`,
    method: "get",
    params,
  });
}

async function newSubscribe(data) {
  return await request({
    url: ENDPOINT.NEW_BLOG_SUBSCRIBE,
    method: "post",
    data,
  });
}

async function fetchBlogHead(params) {
  return await request({
    url: `${ENDPOINT.BLOG_RANKMATH}/getHead`,
    method: "get",
    headers: {
      'CF-Access-Client-Id': process.env.NEXT_PUBLIC_SME_CLIENT_ID,
      'CF-Access-Client-Secret': process.env.NEXT_PUBLIC_SME_CLIENT_SECRET,
    },
    params
  });
}


export default {
  fetchCategory,
  fetchPosts,
  subscribe,
  fetchBlogTags,
  newSubscribe,
  fetchBlogUser,
  fetchBlogHead
};