import { Card } from "antd";
import Link from "next/link";
import dayjs from 'dayjs'
import { useRouter } from 'next/router'

export default function CardArticleHighlight(props) {
  var localizedFormat = require('dayjs/plugin/localizedFormat')
  dayjs.extend(localizedFormat)

  const formatDate = (value) => {
    return useRouter().locale === 'en' ? dayjs(value).format('LL').replace(',', '') : dayjs(value).format('DD/MM/YYYY');
  }

  const convertCategory = () => {
    const category = props.postItem._embedded['wp:term'][0]
    return category[0].name || ''
  }

  return (
    <div className="flex rounded overflow-hidden md:flex-col-reverse relative z-[1] h-[360px] xl:h-[300px] lg:h-[250px] md:h-auto">
      <div className="py-10 px-[70px] xl:px-10 lg:px-5 bg-grey-200">
        <div className='meta flex items-center text-[10px] leading-5 mb-1 text-[#000519]'>
          <span className="uppercase">{formatDate(props.postItem.modified)}</span>
          <span className='px-2 text-dark'>|</span>
          <span className="uppercase" dangerouslySetInnerHTML={{ __html: convertCategory() || '' }}></span>
        </div>
        <Link href={`/${props.type}/${props.postItem.slug || ''}`}>
          <h3 className="text-black mb-6 xl:mb-3 line-clamp-2 lg:text-h5 cursor-pointer hover:underline" title={props.postItem.title.rendered || ''} dangerouslySetInnerHTML={{ __html: props.postItem.title.rendered }}></h3>
        </Link>
        {props.postItem.excerpt && props.postItem.excerpt.rendered && <div className='text-body2 lg:text-body3 line-clamp-4 xl:line-clamp-3' dangerouslySetInnerHTML={{ __html: props.postItem.excerpt.rendered }}></div>}
      </div>
      <figure className="h-full aspect-3/2 relative flex-shrink-0">
        {
          props.postItem._embedded['wp:featuredmedia'] && props.postItem._embedded['wp:featuredmedia'].length
            ?
            <img src={props.postItem._embedded['wp:featuredmedia']['0'].source_url}
              className="cursor-pointer w-full h-full object-cover rounded-r"
              alt={props?.postItem?.title?.rendered || 'CyStack thumbnail highlight posts'}
            />
            :
            <div>
              {
                props.type === 'learn' ?
                  <div className="w-full h-full">
                    <div className="content absolute left-5 top-1/2 -translate-y-1/2">
                      <img src="/images/Logo-Learn.svg" className="max-h-[35px] object-contain mb-6" alt="CyStack logo learn" />
                      <h3 dangerouslySetInnerHTML={{ __html: props.postItem.title.rendered }} className="text-white line-clamp-3"></h3>
                    </div>
                    <img src="/images/DefaultThumbnailArticle.png" className="w-full h-full" alt="CyStack image thumbnail" />
                  </div>
                  :
                  <img src={props.defaultThumbnail} className="w-full h-full" alt="CyStack image thumbnail" />
              }
            </div>
        }
      </figure>
    </div>
  )
}